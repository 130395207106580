export default class Cluster {

    static initCluster(bikerList, zoom) {
        var clustersMaster = []
        var groupMarker = []
        var cluster = this.getClusterN(bikerList, zoom)
        cluster.map((cluster, index) => {
            var lenghtChile = Object.keys(cluster).length;
            if (lenghtChile > 1) {
                groupMarker.push(cluster)
            }
            return null 
        })
    
        cluster.map((cluster, index) => {
            var add = true
            groupMarker.map((group, indexJ) => {
                var isSame = group.includes(bikerList[index])
                if (isSame) add = false
                return null 
            })
            if (add) clustersMaster.push(bikerList[index])
            return null 
        })
        //
        var freeSubSet = this.leaveSubSet(groupMarker)

        //Add just 1 point in the map for every group 
        freeSubSet.map((group, index) => {
            var isIncludes = clustersMaster.includes(group[0])
            if (!isIncludes) {
                var clusterMarker = group[0]
                clusterMarker.cluster = true
                clusterMarker.numberC = Object.keys(group).length
                clustersMaster.push(clusterMarker)
            }
            return null 
        })
        return clustersMaster
    }

    static leaveSubSet(groupMarker) {
        var notDelate = []
        var bikerList = []
        var lenghtGroupMarker = Object.keys(groupMarker).length

        // Count all subSet
        for (var i = 0; i < lenghtGroupMarker; i++) {
            var includesList = []
            var j = 0
            for (j; j < lenghtGroupMarker; j++) {
                if(i === j) break 
                var isIncludes = true
                var lengthI = Object.keys(groupMarker[i]).length
                for (var k = 0; k < lengthI; k++) {
                    if (!groupMarker[j].includes(groupMarker[i][k])) {
                        isIncludes = false
                        break
                    }
                }
                if(isIncludes){
                    includesList.push(j)
                }
            }
            var lenghtIncludesList = Object.keys(includesList).length
                var lenghtMax = 0
                var setMax = i
                for(var x = 0; x < lenghtIncludesList; x++){
                    var lenghtI = Object.keys(groupMarker[includesList[x]]).length
                    if(lenghtI >lenghtMax){
                        lenghtMax = lenghtI
                        setMax = x
                    }
                }
                notDelate.push(setMax)
        }
        var lenghtNotDelate = Object.keys(notDelate).length
        for (var z = 0; z < lenghtGroupMarker; z++) {
            for (var w = 0; w < lenghtNotDelate; w++) {
                if (notDelate[w] === z) {
                    bikerList.push(groupMarker[z])
                }
            }
        }
        return bikerList
    }

    // Return groups of points composted from 1 to n elements 
    static getClusterN(bikerList, zoom) {
        var cluster = []
        var length = Object.keys(bikerList).length;
        for (var i = 0; i < length; i++) {
            var chilCluster = []
            chilCluster.push(bikerList[i])
            for (var j = 0; j < length; j++) {
                if(i === j) break
                var distance = this.distance(bikerList[i], bikerList[j])
                var limit = Math.pow(0.58,zoom)

                // Zoom at 22 is the max zoom so it need show all bikers 
                if(zoom === 22) limit = 0 
                if (limit > distance) {
                    chilCluster.push(bikerList[j])
                }
            }
            cluster.push(chilCluster)
        }
        return cluster
    }

    static distance(pointA, pointB) {
        var distance = Math.sqrt(Math.pow(pointA.latitude - pointB.latitude, 2) + Math.pow(pointA.longitude - pointB.longitude, 2))
        return distance
    }
}//<p className="update"> {this.props.textHeading} <span>{this.state.textHeading}</span></p>