import React, { Component } from 'react';
import BodyMap from './Pages/BodyMap';
import LoadingView from './Components/LoadingView';
export default class App extends Component {

  constructor(props) {
    super(props);
    this.loadingRef = React.createRef();
  }
  render() {

    return (
      <React.Fragment>
        <LoadingView ref={this.loadingRef}/>
        <BodyMap loadingRef={this.loadingRef}/>
      </React.Fragment>
    );
  }
}