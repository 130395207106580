import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline, InfoWindow } from 'google-maps-react';
import Utility from '../Components/Utility';
import BikerList from '../Components/BikerList';
import NetHelper from '../Network/HetHelper';
import Cluster from '../Components/Cluster';

/* eslint-disable no-undef */
export class MapClass extends Component {

  constructor(props) {
    super(props);
    this.pathMarkers = []
    this.originList = []
    this.tempPathColor = null
    this.groupMarker = []
    this.isMarkerMidPath = false
    this.textInfoWindow = ""

    // For close the cyclist list with click on the map
    this.bikerListRef = React.createRef();
    this.state = {
      // References of Cluster
      zoom: 22,
      originBikerList: [],
      firstBikerList: [],
      urlBiker: null,

      // Refernces of start bounds 
      isFirstShow: true,
      isFirstShowPath: true,

      // Path
      showSinglePath: false,
      pathListBiker: [],
      pathListBikerNormalized: [],
      pathColor: null,
      isOtherBiker: true,
      biker: [],

      // References of Infowindow
      textInfoWindow: " ",
      nameInfoWindow: " ",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }

    this.bikersStoryLocationCallBack = this.bikersStoryLocationCallBack.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.userCompletePath = this.userCompletePath.bind(this)
    this.refRequestBikersLastPosition = this.refRequestBikersLastPosition.bind(this)
    this.onZoomChanged = this.onZoomChanged.bind(this)
    this.onMapClick = this.onMapClick.bind(this)
  }

  showLoadingView(show) {
    this.props.loadingRef.current.setVisibility(show)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //if(!nextProps.biker)
    nextProps.setNameTextHeadin("")
    let state = {};

    // Define url as "biker"
    var searchParams = new URLSearchParams(window.location.search);
    var biker = searchParams.get("biker") || ""

    // Check if the url if same
    state.isOtherBiker = true
    state.biker = biker.split("/");
    if (state.biker[0] === prevState.biker[0]) {
      state.isOtherBiker = false
    }

    state.showSinglePath = false
    var lenghtList = Object.keys(nextProps.firstBikerList).length;
    if (lenghtList > 0) {

      // originBikerList will use for the BikerList
      state.originBikerList = nextProps.firstBikerList

      // firstBikerList will use in the Map and clusters
      state.firstBikerList = nextProps.firstBikerList
      //Define if the url is corret and can show a path of the biker
      var id = state.biker[0]
      var index = parseInt(state.biker[1], 10)
      if (id === "" || isNaN(index) || index > lenghtList - 1) {
        state.showSinglePath = false
      } else {
        if (nextProps.firstBikerList[index].id === id) {
          state.showSinglePath = true
        }
      }
    }

    if (state.showSinglePath) {
      if (!state) {
        state = {}
      }
      var boundsPath = null
      boundsPath = new nextProps.google.maps.LatLngBounds();
      prevState.pathListBikerNormalized = []
      prevState.pathListBiker.map((biker, i) => {
        prevState.pathListBikerNormalized.push({
          lat: biker.latitude,
          lng: biker.longitude
        })
        boundsPath.extend(new window.google.maps.LatLng(
          biker.latitude,
          biker.longitude
        ))
        // return nothink
        return (" ")
      })
      if (prevState.isFirstShowPath) {
        state.isFirstShowPath = false
        state.pathListBiker = prevState.pathListBiker
        state.pathListBikerNormalized = prevState.pathListBikerNormalized
        state.bounds = boundsPath
      }

    } else {
      var firstBikerList = Object.keys(nextProps.firstBikerList).length;
      if (firstBikerList > 0) {
        if (!state) {
          state = {}
        }
        if (prevState.isFirstShow) {
          var bounds = null
          bounds = new nextProps.google.maps.LatLngBounds();
          nextProps.firstBikerList.map((marker, i) => {
            bounds.extend(new window.google.maps.LatLng(
              marker.latitude,
              marker.longitude
            ))
            return (" ")
          })
          state.bounds = bounds
        } else {
          // If is'nt first show the clusters can to be actived(in onZoomChanged)
          var tempBikers = Cluster.initCluster(prevState.originBikerList, prevState.zoom)
          state.originBikerList = nextProps.firstBikerList
          state.firstBikerList = tempBikers
        }
      }
    }
    return state;
  }

  refRequestBikersLastPosition() {
    this.props.refRequestBikersLastPosition()
    this.setState({
      showSinglePath: false,
      showingInfoWindow: false,
      isFirstShow: true,
    })
  }

  //request complete path
  userCompletePath(user, index) {
    this.setState({
      pathColor: Utility.getColor(index, user.id),
      isFirstShowPath: true,
      showingInfoWindow: false
    })
  }

  bikersStoryLocationCallBack(json) {
    this.showLoadingView(false)
    if (json) {
      var lenghtData = Object.keys(json.data).length
      if (lenghtData > 0) {
        this.setState({
          pathListBiker: json.data,
          pathColor: Utility.getColor(this.state.biker[1], this.state.biker[0]),
          showSinglePath: true,
          isFirstShowPath: true,
          showingInfoWindow: false
        })
      }
    }
  }

  renderPolyline() {
    var lenght = Object.keys(this.state.pathListBiker).length;
    if (this.state.showSinglePath && lenght > 0) {
      this.props.setNameTextHeadin(this.state.pathListBiker[0].name, this.state.pathColor)
      return (<Polyline
        path={this.state.pathListBikerNormalized}
        editable={false}
        strokeColor={this.state.pathColor}
        fillColor="#000000"
        strokeOpacity={0.7}
        strokeWeight={4}
      />)
    }
  }

  renderNameInfoWindow() {
    return (<p>{this.state.nameInfoWindow}</p>)
  }

  renderMarker(marker, index) {
    var iconMarker = Utility.getIcon(index, marker.id)
    var textLabal = marker.name.substring(0, 3)
    var title = marker.name
    var isClickable = false
    var zIndex = index
    var colorLabel = 'white'

    //Change the label's text if render the markers for the path
    if (this.state.showSinglePath) {
      textLabal = Utility.getHour(marker.time)
      title = Utility.getHourSecods(marker.time)
      isClickable = true
    }

    if (marker.cluster) {
      marker.cluster = false
      iconMarker = Utility.getClusterIcon()
      textLabal = " " + marker.numberC
    }

    if (this.isMarkerMidPath) {
      isClickable = true
      iconMarker = Utility.getMidPathIcon(index, marker.id)
      textLabal = " "
      title = Utility.getHourSecods(marker.time)
      zIndex = -1
    }

    return (<Marker
      // Date for infowindow
      id={marker.id}
      title={title}
      key={index}
      name={marker.name}
      clickable={isClickable}

      icon={iconMarker}
      onClick={this.onMarkerClick}
      //Setting label
      zIndex={zIndex}
      label={{
        color: colorLabel,
        fontWeight: "bold",
        strokeWeight: 1,
        text: textLabal,
      }}
      position={new window.google.maps.LatLng(
        marker.latitude,
        marker.longitude
      )}
    />)
  }

  onMarkerClick = (props, marker, e) =>
    // set onClick only in pathMarker
    this.state.showSinglePath ?
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
        textInfoWindow: props.title,
        nameInfoWindow: props.name
      }) : null

  onMapClick() {
    if (this.bikerListRef.current.state.showList) {
      this.bikerListRef.current.onClickShowList()
    }
  }

  // If you set the zoom at liv 11 of the map active the clusters
  onZoomChanged = (props, map) => {
    if (!this.state.showSinglePath) {
      var zoom = map.getZoom()
      if (zoom === 11) {
        this.setState({
          isFirstShow: false
        })
      }
      if (!this.state.isFirstShow) {
        var scale = 2
        var multi = (zoom % scale)
        if (multi === 0) {
          this.setState({
            zoom: zoom,
            // isFirstShow = false active the cluster in getDerivedStateFromProps 
            isFirstShow: false
          })
        }
      }
    }
  }

  initPathsMarker(pathMarkers) {
    var lenght = Object.keys(this.state.pathListBiker).length;
    if (lenght > 0) {
      // Init Array of pathMarker 
      pathMarkers.push(this.renderMarker(this.state.pathListBiker[0], 0))
      this.isMarkerMidPath = true
      for (var i = 1; i < lenght - 1; i++) {
        pathMarkers.push(this.renderMarker(this.state.pathListBiker[i], i))
      }
      this.isMarkerMidPath = false
      if (lenght !== 1) pathMarkers.push(this.renderMarker(this.state.pathListBiker[lenght - 1], lenght - 1))

      this.countMove++
      if (this.countMove === lenght) {
        this.countMove = 0
      }
    }
  }


  render() {
    const style = {
      overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'fixed',
      bottom: '0px',
      width: '100%',
      height: '100%',
      borderTop: 'solid 120px white',
      borderBottom: 'solid 56px white',
    }

    var pathMarkers = []
    var pathPolyline = null
    if (this.state.isOtherBiker) {
      NetHelper.getBikersStoryLocation(this.bikersStoryLocationCallBack, this.state.biker[0])
      this.showLoadingView(true)
    } else {
      this.initPathsMarker(pathMarkers)
      pathPolyline = this.renderPolyline()
    }

    return (
      <React.Fragment>
        <Map google={this.props.google}
          disableDefaultUI={true}
          style={style}
          bounds={this.state.bounds}
          onZoom_changed={this.onZoomChanged}
          onClick={this.onMapClick}
        >
          {pathPolyline}
          {this.state.showSinglePath
            ?
            //Array of pathMarker 
            pathMarkers
            :
            this.state.firstBikerList
              ?
              this.state.firstBikerList.map((marker, index) => {
                return this.renderMarker(marker, index)
              })
              :
              null
          }
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <React.Fragment>
              <p style=
                {{
                  color: Utility.getColor(this.state.selectedPlace.key, this.state.selectedPlace.id),
                  fontSize: '20px',
                  fontWeight: 'bold'
                }}>
                {this.state.textInfoWindow}
              </p>
              <p>{this.state.nameInfoWindow}</p>
            </React.Fragment>
          </InfoWindow>
        </Map>
        <div className="ftr">
          <BikerList ref={this.bikerListRef} userCompletePath={this.userCompletePath} firstBikerList={this.state.originBikerList} refRequestBikersLastPosition={this.refRequestBikersLastPosition} position="absolute" />
        </div>
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyD21r352RF6igsO9XD-WBGUPDqDXrhtejk")
})(MapClass)