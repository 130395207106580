import React, { Component } from 'react';
import Utility from './Utility';
import List from './List';
import { BrowserRouter as Router, Route } from "react-router-dom";

export default class UsersHelper extends Component {
    constructor(props) {
        super(props);
        this.onUserClick = this.onUserClick.bind(this)
    }

    onUserClick(event, user, index, history) {
        event.preventDefault();
        event.stopPropagation()

        //urls
        const url = setParams({ biker: user.id }) + '/' + index;
        history.push(`?${url}`);

        this.props.userCompletePath(user, index)
        this.props.onClickListCallBack(false)
    }

    renderRow(user, index) {
        var color = Utility.getColor(index, user.id)
        return (
            <React.Fragment key = {index}>
                <Router>
                    <React.Fragment>
                        <Route
                            path="/"
                            render={({ location, history }) => {
                                return (
                                    <button id={user.id} className="dropup" onClick={(event) => this.onUserClick(event, user, index, history)} >
                                        <div className="cyclist">
                                            <svg height="50" width="50" viewBox="0 0 52 60" enableBackground="new 0 0 52 60" space="preserve">
                                                <path d="M26,0C11.6,0,0,11.6,0,26c0,11.8,7.8,21.7,18.5,24.9L26,60l7.5-9.1C44.2,47.7,52,37.8,52,26C52,11.6,40.4,0,26,0z M29.9,12.8c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3S28.7,12.8,29.9,12.8z M17.9,36.2c-3.2,0-5.7-2.5-5.7-5.7 c0-3.2,2.5-5.7,5.7-5.7s5.7,2.5,5.7,5.7S21.1,36.2,17.9,36.2z M27,25.7v7.1h-2.3v-5.7l-3.7-3.2c-0.5-0.3-0.7-0.9-0.7-1.6 	c0-0.6,0.2-1.1,0.7-1.6l3.2-3.2c0.3-0.5,0.9-0.7,1.6-0.7c0.7,0,1.3,0.2,1.8,0.7l2.2,2.2c1,1,2.4,1.7,4.1,1.7v2.3 c-2.4,0-4.4-0.9-5.8-2.4l-0.9-0.9l-2.7,2.7L27,25.7z M33.9,36.2c-3.2,0-5.7-2.5-5.7-5.7c0-3.2,2.5-5.7,5.7-5.7	c3.2,0,5.7,2.5,5.7,5.7S37.1,36.2,33.9,36.2z"
                                                    fill={color} />
                                            </svg>
                                            <p>{user.name}</p>
                                        </div>
                                    </button>)
                            }}
                        />
                    </React.Fragment>
                </Router>
            </React.Fragment>
        );
    }

    render() {
        return (
            <List>{this.props.firstBikerList.map((user, index) => {
                return this.renderRow(user, index)
            })}</List>
        );
    }
}

function setParams({ biker }) {
    const searchParams = new URLSearchParams();
    searchParams.set("biker", biker || "");
    return searchParams.toString();
}