
const BASE_URL = "https://europe-west1-stetel-smart-beacon.cloudfunctions.net/"

const BIKERS_FAST_LOCATION = "bikersLastLocation"

const BIKERS_FAST_LOCATION2 = "exportUniqueBiker?id="

export default class NetHelper {
    static getBikersLastLocation(callback) {
        const url = BASE_URL + BIKERS_FAST_LOCATION
        this.baseRequest(url, "GET", this.getBaseHeader(), null, callback, "BIKERS_FAST_LOCATION")
    }

    static getBikersStoryLocation(callback, id) {
        const url = BASE_URL + BIKERS_FAST_LOCATION2 + id + "&hours=12"
        this.baseRequest(url, "GET", this.getBaseHeader(), null, callback, "BIKERS_FAST_LOCATION")
    }

    static getBaseHeader() {
        return { "Content-Type": "application/json", "Accept": "application/json" }
    }

    static baseRequest(url, method, headers, body, callback, type) {
        var fetchCore = {
            method: method,
            headers: headers
        }

        if (method === "POST" || method === "PATCH") {
            fetchCore["body"] = body
        }

        fetch(url, fetchCore)
            .then(response => {
                const statusCode = response.status
                console.log("staus: " + statusCode + " type " + type)
                switch (statusCode) {
                    // TODO: error type
                    default:
                        return response.json()
                }
            })
            .then(json => {
                if (json) {
                    callback(json)
                } else {
                    callback({ errorCode: 500 })

                }
            })
            .catch(error => {
                console.log("BaseRequest.catch.Error: " + error)
            });
    }
}