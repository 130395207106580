import React, { Component } from 'react';
import UsersHelper from '../Components/UsersHelper';
//import navigator from 'react-web-share-api';
//import list from '../Components/list.css';
export default class BikerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showList: false,
        }

        this.onClickShowList = this.onClickShowList.bind(this)
        this.onClickLastPosistions = this.onClickLastPosistions.bind(this)
        this.onClickListCallBack = this.onClickListCallBack.bind(this)
    }
    onClickShowList() {
        if (this.state.showList){
            this.setState({ showList: false })
        } else {
        this.setState({ showList: true }
        )}
    }

    onClickLastPosistions() {
        this.props.refRequestBikersLastPosition()
    }

    onClickListCallBack(show) {
        this.setState({ showList: show })
    }

    onClickShare(){
        var navigator = window.navigator
        if (navigator.share) {
                navigator.share({
                    title: 'GBI Europe 2019',
                    text: 'Several hundreds of ambitioned charity cyclists will follow the call of the Global Biking Initiative and pedal from Milan over the Alps to Munich',
                    url: window.location.href
                }).then(() => {
                    console.log("------Share: Shared it")
                })
                .catch(err => {
                    console.log("------Share: Couldn't share")
                });
              } else {
                console.log("------Share: Couldn't share");
              }
    }

    render() {
        return (
            <React.Fragment>
                <div className="bottom-aligner">
                    {window.navigator.share ? 
                    <button className="share-icon" onClick={() => this.onClickShare()}></button>
                    : null
                    }
                    <button className="all-on-map" onClick={() => this.onClickLastPosistions()} >All cyclists&apos; positions</button>
                    <div className="dropdown">
                        <button onClick={this.onClickShowList} className="dropbtn">CYCLISTS <i className="fa fa-caret-up"></i></button>
                        {this.state.showList ?
                            <div id="myDropdown" className="dropdown-content" >
                                <div className="sp-64"></div>
                                <div className="list">
                                    <UsersHelper userCompletePath={this.props.userCompletePath} firstBikerList={this.props.firstBikerList} onClickListCallBack={this.onClickListCallBack} />
                                </div>
                            </div>
                            : null}
                    </div>

                </div>
            </React.Fragment>);
    }
}