import React, { Component } from 'react';

export default class LoadingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoadingView: false
        };
    }

    setVisibility(visibility) {
        this.setState({ showLoadingView: visibility })
    }

    renderView() {
        return (<div className="dialog-overlay">
            <div className="dialog-aligner">
                <div className="dialog-overlay-waitmessage">
                    <div className="loader"></div>
                    Please wait...
            </div>
            </div>
        </div>)
    }

    render() {
        let loadingview = null
        if (this.state.showLoadingView) {
            loadingview = this.renderView()
        }
        return (loadingview);
    }
}