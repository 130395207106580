import React, { Component } from 'react';
import MapClass from './MapClass';
import HeadingMap from '../Pages/HeadingMap';
import NetHelper from '../Network/HetHelper';
import { BrowserRouter as Router, Route } from "react-router-dom";

export default class BodyMap extends Component {

  constructor(props) {
    super(props);
    this.headingRef = React.createRef();
    this.textName = " "
    this.upDatingMap = null
    this.history = null
    this.state = {
      firstBikerList: [],
      textHeading: null,
      urlBiker: null
    }

    this.bikersLsLocationCallBack = this.bikersLsLocationCallBack.bind(this);
    this.refRequestBikersLastPosition = this.refRequestBikersLastPosition.bind(this)
    this.setNameTextHeading = this.setNameTextHeading.bind(this)
  }

  componentDidMount() {
    this.requestBikersLastPosition()
  }


  requestBikersLastPosition() {
    NetHelper.getBikersLastLocation(this.bikersLsLocationCallBack)
    this.showLoadingView(true)
  }

  showLoadingView(show) {
    this.props.loadingRef.current.setVisibility(show)
  }

  bikersLsLocationCallBack(json) {
    this.showLoadingView(false)
    //Set Date last update
    var timeUpdate = new Date()
    if (json) {
      if (json.listPos) {
        this.headingRef.current.setDateHeading()

        this.setState({
          firstBikerList: json.listPos,
          textHeading: "Last update " + timeUpdate.toLocaleTimeString()
        })
        //timeUpdate: timeUpdate.toLocaleString("en-US", {timeZone: "America/New_York"})})
      }
    }
  }

  setNameTextHeading(name, color) {
    this.headingRef.current.setNameTextHeading(name, color)
  }

  refRequestBikersLastPosition() {
    this.history.push('');
    this.requestBikersLastPosition()
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <React.Fragment>
            <Route
              path="/"
              render={({ location,history}) => {
                this.history = history
                const { biker } = getParams(location);
                return (
                  <React.Fragment>
                    <HeadingMap ref={this.headingRef} textHeading={this.state.textHeading} />
                    <MapClass
                      //For the share
                      biker={biker} 

                      setNameTextHeadin={this.setNameTextHeading}
                      headingRef={this.headingRef}
                      loadingRef={this.props.loadingRef}
                      firstBikerList={this.state.firstBikerList}
                      refRequestBikersLastPosition={this.refRequestBikersLastPosition} />
                  </React.Fragment>);
              }}
            />
          </React.Fragment>
        </Router>
      </React.Fragment>
    );
  }
}
function getParams(location) {
  var searchParams = new URLSearchParams(location.search);
  return {
    biker: searchParams.get("biker") || ""
  };
}

