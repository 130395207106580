import React, { Component } from 'react';

// function FormattedDate(props) {
//     return <p className="update"> Last update: {props.date.toLocaleTimeString()}</p>;
// }

export class HeadingMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPath: false,
            textHeading : "",
            colorText: ""
           // date: new Date()
        }
    }

    setNameTextHeading(name,color){
        this.setState({
          textHeading: name,
          colorText: color,
          isPath: true
        })
      }
    
      setDateHeading(){
          this.setState({
              isPath: false
          })
      }
    
    render() {
        return (
            <div className="hdr">
                <div className="top-aligner">
                    <div className="logos sx">
                        <a href="https://www.urbantracker.com" target="_blank" rel="noopener noreferrer"><img src="images/icons/Urban-Tracker-logo.png" alt="Urban-Tracker-logo" /></a>
                    </div>
                    <div className="logos dx">
                        <a href="https://www.gbi-event.org/en/events/gbi-europe-2019.html" target="_blank" rel="noopener noreferrer"><img src="images/icons/gbi-logo.png" alt="gbi-logo" /></a>
                    </div>
                </div>
                <div className="top-aligner">
                    {!this.state.isPath
                     ?
                     <div className="screen-info">
                    <p className="update"> {this.props.textHeading}</p><p className="update" style = {{float : 'right', textAlign: 'right', whiteSpace: 'nowrap', width: '20%'}}>
                    Last 12h
                  </p>
                  </div>
                    : <div className="screen-info"><p className="update" style = {{float : 'left', width:'80%'}} >
                         {this.props.textHeading} <span style=
                        {{
                        color: this.state.colorText,
                        }} >{this.state.textHeading}</span> </p> <p className="update" style = {{float : 'right', textAlign: 'right', whiteSpace: 'nowrap', width: '20%'}}>
                        Last 12h
                      </p>
                      </div>
                    }
                </div>
            </div>
        );
    }

}
export default (HeadingMap)
