
export default class Utility {

    static hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    static intToRGB(i) {
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    static getIcon(index, id){
        var color = this.getColor(index, id)
        var icon = {
            url: 'data:image/svg+xml;utf-32, '+
            '<svg width="50" height="50" viewBox="0 0 52 60" enableBackground="new 0 0 52 60" xmlns="http://www.w3.org/2000/svg"> '+
            '<path fill="'+color+'" d="M52,26C52,11.6,40.4,0,26,0S0,11.6,0,26c0,11.8,7.8,21.7,18.5,24.9L26,60l7.5-9.1C44.2,47.7,52,37.8,52,26z"></path> '+
            '<path fill="white" d="M28.5,8.9c0.8,0,1.5-0.7,1.5-1.5S29.3,6,28.5,6S27,6.7,27,7.5S27.7,8.9,28.5,8.9z M20.8,13.7 c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S22.8,13.7,20.8,13.7z M25,12.6l1.8-1.8l0.6,0.6c1,1,2.2,1.5,3.7,1.5v-1.5 c-1.1,0-2-0.4-2.6-1.1L27.1,9c-0.4-0.3-0.7-0.4-1.2-0.4s-0.8,0.1-1,0.4l-2,2c-0.3,0.3-0.4,0.7-0.4,1c0,0.4,0.1,0.8,0.4,1l2.3,2v3.7 h1.5v-4.5L25,12.6z M31,13.7c-2,0-3.7,1.6-3.7,3.7S29,21,31,21s3.7-1.6,3.7-3.7S33.1,13.7,31,13.7z"></path> '+
            '</svg>'
        }
        return icon
    }

    static getClusterIcon(){
        var icon = {
            path:"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            // fillColor: this.getColor(index, id),
            fillColor: "#006064",
            fillOpacity: 1.0,
            strokeWeight: 0,
            scale: 1,
        }
        return icon
    } 

    static getMidPathIcon(index, id){
        var icon = {
            path:"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillOpacity: 1,
            strokeWeight: 1,
            scale: 0.2,
            fillColor: "white",
        }
        return icon
    }

    static getAnimatedMarkIcon(color){
        var iconA = {
            path:"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillOpacity: 1,
            strokeWeight: 0.2,
            scale: 0.4,
            fillColor: color,
        }
        return iconA
    }

    static getColor(index, id){
        index = localStorage.getItem(id)
        if(index){
             return this.colorList[index]                    
        }else{
            index = localStorage.getItem("INDEX")
            if(index){
                var temp = JSON.parse(index)+1
                if(index > this.colorList.length-1){
                    index = index % this.colorList.length
                }
                localStorage.setItem(id, temp)
                localStorage.setItem("INDEX", temp)
                return this.colorList[temp]                    
            }else{
                localStorage.setItem(id, 0)
                localStorage.setItem("INDEX", 0)
                return this.colorList[0]    
            }
        }
    }

    static getHour(stringDate){
        //2019-06-12T06:42:05.000Z
        var dataHour_minut_second = stringDate.split(":");
        var hour = dataHour_minut_second[0].split("T")
        hour= hour[1]
        var intergerHour = parseInt(hour, 10)
        intergerHour = intergerHour + 2
        if(intergerHour === 24) intergerHour = 0
        if(intergerHour === 25) intergerHour = 1
        var minut = dataHour_minut_second[1]
        var date = intergerHour + ":" + minut
        return date
    }

    static getHourSecods(stringDate){
        //2019-06-12T06:42:05.000Z
        var dataHour_minut_second = stringDate.split(":");
        var hour = dataHour_minut_second[0].split("T")
        hour= hour[1]
        var intergerHour = parseInt(hour, 10)
        intergerHour = intergerHour + 2
        if(intergerHour === 24) intergerHour = 0
        if(intergerHour === 25) intergerHour = 1
        var minut = dataHour_minut_second[1]
        var secondZ = dataHour_minut_second[2].split(".")
        var second = secondZ[0]
        var date = intergerHour + ":" + minut + ":"+ second
        return date
    }

    static colorList = [	
        "rgb(255,152,0)",
        "rgb(33,150,243)",
        "rgb(156,39,176)",
        "rgb(244,67,54)",
        "rgb(76,175,80)",
        "rgb(233,30,99)",
        "rgb(0,150,136)",
        "rgb(255,152,0)",
        "rgb(63,81,181)",
        "rgb(103,58,183)",
        "rgb(0,188,212)",
        "rgb(139,195,74)",
    ]
}